<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">Статьи</h3>
      </div>
      <div class="card-toolbar">
        <router-link to="/articles/create">Создать</router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <v-server-table
          :url="url"
          :columns="columns"
          :options="options"
          :ref="tableName"
        >
          <div slot="afterFilter" class="row w-50 ml-5 h-38px">
              <div class="row">
                <div class="col-6">
                <el-select
                    v-model="filters.type"
                    filterable
                    placeholder="Тип"
                    clearable
                    @change="updateFilters($event, 'type')"
                >
                    <el-option
                    v-for="item in types"
                    :key="item.id"
                    :label="`${item.title}`"
                    :value="item.id"
                    >
                    </el-option>
                </el-select>
                </div>
                <div class=" col-6">
                <el-select
                    v-model="filters.status"
                    filterable
                    placeholder="Статус"
                    clearable
                    @change="updateFilters($event, 'status')"
                >
                    <el-option
                    v-for="item in statuses"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                    >
                    </el-option>
                </el-select>
                </div>
              </div>
          </div>
          <div slot="active" slot-scope="props">
            <span v-if="props.row.active == 1" class="text-success">
              Активна</span
            >
            <span v-if="props.row.active == 0" class="text-danger">
              Не активна</span
            >
          </div>
          <div slot="published_at" slot-scope="props">
            {{ props.row.published_at | moment('DD.MM.YY') }}
          </div>
          <div
            class="text-center"
            slot="actions"
            slot-scope="props"
            style="width: 100px"
          >
            <div class="dropdown dropdown-inline">
              <BaseTableEditBtn @click="editEntity(props.row)" />
              <BaseTableDeleteBtn @click="deleteEntity(props.row.id)" />
            </div>
          </div>
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
import { vueTableMixin } from "@/views/mixins/vueTableMixin";
import { Event } from "vue-tables-2";

export default {
  name: "Index",
  mixins: [vueTableMixin],

  data() {
    return {
      modalName: "articles",
      tableName: "articles",
      url: "articles",
      columns: [
        "id",
        "type_title",
        "title",
        "short_description",
        'published_at',
        "active",
        "actions",
      ],
      options: {
        headings: {
          type_title: "Тип",
          title: "Название",
          short_description: "Краткое описание",
          active: "Статус",
          published_at: 'Дата публикации',
          actions: "Действия",
        },
        // filterable: false,
        customFilters: ["type", "status"],
      },
      filters: {
        type: null,
        status: null,
      },
      types: [
        {
          id: "article",
          title: "Статья",
        },
        {
          id: "new",
          title: "Новость",
        },
        {
          id: "advert",
          title: "Объявление",
        },
        {
          id: "note",
          title: "Заметка",
        },
        {
            id: 'event',
            title: 'Событие'
        }
      ],
      statuses: [
        {
          id: "active",
          title: "Активен",
        },
        {
          id: "inactive",
          title: "Не активнен",
        },
      ],
    };
  },
  methods: {
    updateFilters(val, cat) {
      Event.$emit("vue-tables.filter::" + cat, this.filters[cat]);
    },
    save() {
      this.$refs[this.tableName].refresh();
      this.entity = null;
    },
    editEntity(entity) {
      console.log(entity);
      this.$router.push({
        name: "ArticleEdit",
        params: {
          id: entity.id,
        },
      });
    },
  },
};
</script>
